import React, { FunctionComponent, useState } from 'react';

import { Play, PlayBook, Snapshot } from '../../PlayBook';
import {
  getImage,
  getPlayerLabel,
  getSurfaceDimensions,
  getSurfaceImage,
  getZIndex,
  interpolate,
} from './animation-utilities';
import './PlayAnimator.scss';
import { deepCopySnapshot } from '../../utils';
import { ArrowsLayer } from './ArrowsLayer';
import { ADS_HEIGHT } from '../AdBlock/AdBlock';

type PlayAnimatorProps = {
  playbook: PlayBook;
  play: Play;
  snapshotCounter: number;
  isEditor: boolean;
  dirtySnapshot: Snapshot | null;
  setDirtySnapshot: (snapshot: Snapshot | null) => void;
  isPlaying: boolean;
  showArrows: boolean;
};

export const PlayAnimator: FunctionComponent<PlayAnimatorProps> = ({
  playbook,
  play,
  snapshotCounter,
  isEditor,
  dirtySnapshot,
  setDirtySnapshot,
  isPlaying,
  showArrows,
}: PlayAnimatorProps) => {
  const [selectedElementKey, setSelectedElementKey] = useState<string>('');

  const getDescriptionDiv = (snapshot: Snapshot) => {
    // @ts-ignore
    const descriptionElement = snapshot.elements['description1'];
    if (!descriptionElement) {
      return null;
    }

    const descriptionStyle = {
      top: descriptionElement.yposition,
      left: descriptionElement.xposition,
    };

    const selectedClassName =
      'description1' === selectedElementKey ? ' selected' : '';
    const editableClassName = isEditor ? ' editable' : '';
    const className =
      'snapshot-description' + selectedClassName + editableClassName;

    const onSelect = isEditor
      ? () => setSelectedElementKey('description1')
      : () => {};
    return (
      <div
        className={className}
        style={descriptionStyle}
        onMouseDown={onSelect}
        onTouchStart={onSelect}
        onTouchEnd={() => setSelectedElementKey('')}
      >
        {snapshot.description}
      </div>
    );
  };

  const snapshotIndex = Math.floor(snapshotCounter);
  const snapshot = dirtySnapshot
    ? dirtySnapshot
    : play.snapshots[snapshotIndex];
  const nextSnapshot =
    play.snapshots.length > snapshotIndex + 1
      ? play.snapshots[snapshotIndex + 1]
      : snapshot;

  const elementDivs = Object.keys(snapshot.elements).map((key) => {
    if (key.startsWith('description')) {
      return null;
    }

    const elementLabel = getPlayerLabel(key, playbook);
    const backgroundImage = getImage(key, playbook.sport, playbook);
    const interpolationRatio = snapshotCounter - snapshotIndex;

    // @ts-ignore
    const element = snapshot.elements[key];
    // @ts-ignore
    const nextElement = nextSnapshot.elements[key];

    const zIndex = getZIndex(key);
    const selectedClassName = key === selectedElementKey ? ' selected' : '';
    const editableClassName = isEditor ? ' editable' : '';

    const elementStyle = {
      top: interpolate(
        element.yposition,
        nextElement.yposition,
        interpolationRatio
      ),
      left: interpolate(
        element.xposition,
        nextElement.xposition,
        interpolationRatio
      ),
      zIndex,
    };

    if (backgroundImage) {
      // @ts-ignore
      elementStyle.backgroundImage = `url(${backgroundImage}`;
    }

    const className = 'element ' + selectedClassName + editableClassName;

    const onSelect = isEditor ? () => setSelectedElementKey(key) : () => {};

    return (
      <div
        className={className}
        style={elementStyle}
        key={'element' + key}
        onMouseDown={onSelect}
        onTouchStart={onSelect}
        onTouchEnd={() => setSelectedElementKey('')}
      >
        <div className="label">{elementLabel}</div>
      </div>
    );
  });

  const hasDescription = play.description && play.description.length > 0;
  const descriptionDiv = hasDescription ? getDescriptionDiv(snapshot) : null;

  const [width, height] = getSurfaceDimensions(playbook.sport);
  const screenStyle = {
    backgroundImage: getSurfaceImage(playbook.sport),
    width: `${width}px`,
    height: `${height}px`,
  };

  const onMove = (rawX: number, rawY: number) => {
    if (selectedElementKey === '') {
      return;
    }

    const adjustedX = rawX - 20;
    const adjustedY = rawY - 46 - ADS_HEIGHT;

    const currentSnapshot = play.snapshots[snapshotIndex];

    const newDirtySnapshot: Snapshot = dirtySnapshot
      ? deepCopySnapshot(dirtySnapshot)
      : deepCopySnapshot(currentSnapshot);

    const changedElement = newDirtySnapshot.elements[selectedElementKey];
    changedElement.xposition = adjustedX - 10;
    changedElement.yposition = adjustedY - 10;
    setDirtySnapshot(newDirtySnapshot);
  };

  const onMouseMove = (event: React.MouseEvent) => {
    onMove(event.pageX, event.pageY);
  };

  const onTouchMove = (event: React.TouchEvent) => {
    event.preventDefault();
    const { touches } = event;
    const touch = touches[0];

    const adjustedY = touch.clientY + ADS_HEIGHT;

    // push a bit to the right and up so their finger doesn't cover the element
    const FINGER_GAP = 40;
    onMove(touch.clientX + FINGER_GAP, adjustedY - FINGER_GAP - ADS_HEIGHT);
  };

  return (
    <div
      style={screenStyle}
      className="PlayAnimator"
      onMouseUp={() => setSelectedElementKey('')}
      onMouseMove={onMouseMove}
      onTouchMove={onTouchMove}
    >
      {showArrows && (
        <ArrowsLayer
          width={width}
          height={height}
          play={play}
          snapshotCounter={snapshotCounter}
          dirtySnapshot={dirtySnapshot}
          isPlaying={isPlaying}
        />
      )}
      {elementDivs}
      {descriptionDiv}
    </div>
  );
};
