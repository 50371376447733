import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const KeyError: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Key Error</div>
      <p>
        We were unable to find your key. Please make sure that you have the
        right link and the complete link.
      </p>
    </div>
  );
};
