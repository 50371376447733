import { FunctionComponent } from 'react';

import { Play, Position, Snapshot } from '../../PlayBook';

type ArrowsLayerProps = {
  width: number;
  height: number;
  play: Play;
  snapshotCounter: number;
  dirtySnapshot: Snapshot | null;
  isPlaying: boolean;
};

function buildLine(key: string, position0: Position, position1: Position) {
  const x0 = position0.xposition + 10;
  const x1 = position1.xposition + 10;

  const y0 = position0.yposition + 10;
  const y1 = position1.yposition + 10;

  if (Math.pow(x0 - x1, 2) + Math.pow(y0 - y1, 2) < 2) {
    return null;
  }

  return (
    <path
      key={key}
      className="line"
      d={`M ${x0} ${y0} L ${x1} ${y1}`}
      markerEnd="url(#arrowhead)"
    />
  );
}

export const ArrowsLayer: FunctionComponent<ArrowsLayerProps> = ({
  width,
  height,
  play,
  snapshotCounter,
  dirtySnapshot,
  isPlaying,
}: ArrowsLayerProps) => {
  const viewBox = `0 0 ${width} ${height}`;

  const snapshotIndex = Math.floor(snapshotCounter);

  const buildLines = () => {
    if (snapshotIndex >= play.snapshots.length - 1) {
      return null;
    }

    const currentSnapshot = play.snapshots[snapshotIndex];
    const nextSnapshot = play.snapshots[snapshotIndex + 1];

    return Object.keys(currentSnapshot.elements).map((key) => {
      if (!key.startsWith('square')) {
        return null;
      }

      const position0 = currentSnapshot.elements[key];
      const position1 = nextSnapshot.elements[key];

      return buildLine('line_' + key, position0, position1);
    });
  };

  const lines = buildLines();

  return (
    <svg
      style={{ width: `${width}px`, height: `${height}px` }}
      viewBox={viewBox}
    >
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="8"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" />
        </marker>
      </defs>
      {lines}
    </svg>
  );
};
