import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { PlayBook } from '../../PlayBook';
import { createShareLink } from '../../utils';

type ShareProps = {
  playbook: PlayBook;
};
export const Share: FunctionComponent<ShareProps> = ({
  playbook,
}: ShareProps) => {
  return (
    <div className="StaticContent">
      <p className="static-content-title">Share</p>
      {createShareLink(
        playbook,
        'The players get a link that only allows them to view the plays.',
        'playersKey'
      )}
      {createShareLink(
        playbook,
        'Assistant coaches and other collaborators get a link that allows them to edit the plays.',
        'coachesKey'
      )}
      {createShareLink(
        playbook,
        'You should keep your owners key somewhere safe. Maybe email it to yourself or copy it to a google doc.',
        'ownersKey'
      )}
    </div>
  );
};
