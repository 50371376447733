import { FunctionComponent } from 'react';
import './CurrentPlay.scss';
import { Play } from '../../PlayBook';
import { deepCopyPlay } from '../../utils';

type CurrentPlayProps = {
  play: Play;
  isOwner: boolean;
  isCoach: boolean;
  onPlayChange: (play: Play) => void;
};

export const CurrentPlay: FunctionComponent<CurrentPlayProps> = ({
  play,
  isOwner,
  isCoach,
  onPlayChange,
}: CurrentPlayProps) => {
  const isEditor = isOwner || isCoach;

  const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    if (play.name !== newName) {
      const newPlay = deepCopyPlay(play);
      newPlay.name = newName;
      onPlayChange(newPlay);
    }
  };

  const onDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newDescription = event.target.value;
    if (play.description !== newDescription) {
      const newPlay = deepCopyPlay(play);
      newPlay.description = newDescription;
      onPlayChange(newPlay);
    }
  };

  return (
    <div className="CurrentPlay content">
      <div className="label">Play</div>
      {isEditor ? (
        <input onChange={onNameChange} value={play.name} />
      ) : (
        <div>{play.name}</div>
      )}
      {isEditor ? (
        <textarea onChange={onDescriptionChange} value={play.description} />
      ) : (
        <textarea value={play.description} disabled={true} />
      )}
    </div>
  );
};
