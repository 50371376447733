import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const About: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">About</div>
      <p>
        ShareThePlay.com can help you create plays and share them with coaches
        and players.
      </p>

      <p>
        We hope that this tool helps you visualize your plays and helps your
        players remember them.
      </p>
    </div>
  );
};
