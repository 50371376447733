import { FunctionComponent } from 'react';
import preval from 'preval.macro';

import './SplashScreen.scss';
import { TopNavigation } from '../Navigation/TopNavigation';
import { ListOfSports } from '../StaticContent/ListOfSports';
import { SamplePlays } from '../StaticContent/SamplePlays';
import { Overview } from '../StaticContent/Overview';
import { CreateAPlay } from '../StaticContent/CreateAPlay';
import { SeeThePlay } from '../StaticContent/SeeThePlay';
import { Collaborate } from '../StaticContent/Collaborate';
import { Privacy } from '../StaticContent/Privacy';

export const SplashScreen: FunctionComponent = () => {
  const buildStamp = preval`module.exports = new Date().toLocaleString();`;
  return (
    <div className="SplashScreen App">
      <TopNavigation sport={null} currentModal="" setCurrentModal={() => {}} />
      <div className="splash-body">
        <ListOfSports />
        <SamplePlays />
        <Overview />
        <CreateAPlay />
        <SeeThePlay />
        <Collaborate />
        <Privacy />
      </div>
      <div className="BottomNavigation">
        <div className="fine-print">
          <span>Copyright &copy; 2011- ShareThePlay.com</span>
          <span>Built on {buildStamp}</span>
        </div>
      </div>
    </div>
  );
};
