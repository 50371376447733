import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Collaborate: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">Collaborate</p>
      <ul>
        <li>
          If you want to let your assistants collaborate, then you can send an
          email to them with the Coaches Link. To see the links, use the 'Share'
          button.
        </li>
        <li>They will be able to add or edit plays.</li>
        <li>
          Only a holder of the Owner Link can edit the playbook information,
          share the plays or change the links
        </li>
        <li>
          Please take turns editing. If you try to work at the same time the
          slowest person wins...
        </li>
      </ul>
    </div>
  );
};
