import { FunctionComponent } from 'react';
import './Link.scss';

type NavigationLinkProps = { href: string; text: string };

export const NavigationLink: FunctionComponent<NavigationLinkProps> = ({
  href,
  text,
}: NavigationLinkProps) => {
  return (
    <div className="link NavigationLink">
      <a className="link-text" href={href} target="_blank" rel="noreferrer">
        {text}
      </a>
    </div>
  );
};
