import { FunctionComponent } from 'react';

import { Play } from '../../PlayBook';
import './PlaybackControl.scss';
import { createButton } from '../../utils';

type PlaybackControlProps = {
  play: Play;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  snapshotCounter: number;
  setSnapshotCounter: (counter: number) => void;
  playbackSpeed: 'Slow' | 'Medium' | 'Fast';
  setPlaybackSpeed: (speed: 'Slow' | 'Medium' | 'Fast') => void;
  hasDirtySnapshot: boolean;
  showArrows: boolean;
  setShowArrows: (showArrows: boolean) => void;
};

export const PlaybackControl: FunctionComponent<PlaybackControlProps> = ({
  play,
  isPlaying,
  setIsPlaying,
  snapshotCounter,
  setSnapshotCounter,
  playbackSpeed,
  setPlaybackSpeed,
  hasDirtySnapshot,
  showArrows,
  setShowArrows,
}: PlaybackControlProps) => {
  const maxSnapshotIndex = play.snapshots.length - 1;
  const wholeSnapshotCounter = Math.floor(snapshotCounter);
  // todo: replace shim for centering the play button with grid or something not hacky
  const createPlayPauseButton = (className: string) => {
    return (
      <button
        className={className}
        style={{ marginLeft: '26px' }}
        onClick={() => {
          if (wholeSnapshotCounter === maxSnapshotIndex) {
            setSnapshotCounter(0);
          }
          setIsPlaying(!isPlaying);
        }}
        disabled={hasDirtySnapshot}
        title={hasDirtySnapshot ? 'Finish editing the step then play' : ''}
      >
        {isPlaying ? 'Pause' : 'Play'}
      </button>
    );
  };

  const playPauseButton = createPlayPauseButton('main-play-pause');
  const edgePlayPauseButton = createPlayPauseButton('edge-play-pause');

  const snapshotPositionElement = (
    <span>
      {wholeSnapshotCounter + 1} of {play.snapshots.length}
    </span>
  );

  const createSpeedButton = (speed: 'Slow' | 'Medium' | 'Fast') => {
    return (
      <button
        onClick={() => setPlaybackSpeed(speed)}
        disabled={speed === playbackSpeed}
      >
        {speed}
      </button>
    );
  };

  const firstButton = createButton(
    'First',
    () => {
      setSnapshotCounter(0);
    },
    () => snapshotCounter === 0 || hasDirtySnapshot
  );
  const previousButton = createButton(
    'Previous',
    () => {
      setSnapshotCounter(wholeSnapshotCounter - 1);
    },
    () => wholeSnapshotCounter === 0 || hasDirtySnapshot
  );
  const nextButton = createButton(
    'Next',
    () => {
      setSnapshotCounter(wholeSnapshotCounter + 1);
    },
    () =>
      isPlaying ||
      wholeSnapshotCounter === play.snapshots.length - 1 ||
      hasDirtySnapshot
  );
  const lastButton = createButton(
    'Last',
    () => {
      setSnapshotCounter(play.snapshots.length - 1);
      setIsPlaying(false);
    },
    () =>
      isPlaying ||
      wholeSnapshotCounter === play.snapshots.length - 1 ||
      hasDirtySnapshot
  );

  const showArrowsButton = createButton(
    showArrows ? 'Hide Arrows' : 'Show Arrows',
    () => setShowArrows(!showArrows),
    () => false
  );

  return (
    <div className="PlaybackControl content">
      {edgePlayPauseButton}
      <div>
        {playPauseButton}
        {showArrowsButton}
      </div>
      <div>
        {firstButton}
        {previousButton}
        {snapshotPositionElement}
        {nextButton}
        {lastButton}
      </div>
      <div>
        {createSpeedButton('Slow')}
        {createSpeedButton('Medium')}
        {createSpeedButton('Fast')}
      </div>
    </div>
  );
};
