import { FunctionComponent } from 'react';
import './LabelEditor.scss';

type LabelEditorProps = {
  offensePlayerLabels: string[];
  defensePlayerLabels: string[];
  setOffensePlayerLabel: (index: number, label: string) => void;
  setDefensePlayerLabel: (index: number, label: string) => void;
  onClose: () => void;
};

export const LabelEditor: FunctionComponent<LabelEditorProps> = ({
  offensePlayerLabels,
  defensePlayerLabels,
  setOffensePlayerLabel,
  setDefensePlayerLabel,
  onClose,
}: LabelEditorProps) => {
  const offenseInputs = offensePlayerLabels.map((label, index) => {
    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
      setOffensePlayerLabel(index, event.currentTarget.value);
    };
    return (
      <input key={'offenseInput' + index} value={label} onChange={onChange} />
    );
  });

  const defenseInputs = defensePlayerLabels.map((label, index) => {
    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
      setDefensePlayerLabel(index, event.currentTarget.value);
    };
    return (
      <input key={'defenseInput' + index} value={label} onChange={onChange} />
    );
  });

  return (
    <div className="LabelEditor">
      <div className="column">
        <div className="column-title">Offense</div>
        {offenseInputs}
      </div>

      <div className="column">
        <div className="column-title">Defense</div>
        {defenseInputs}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};
