import { FunctionComponent, useEffect, useRef } from 'react';
import React from 'react';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

import './App.scss';
import {
  createPlay,
  createPlaybook,
  deepCopyPlay,
  deepCopyPlayBook,
  fetchDataFromKeys,
  parseKeysFromLocation,
  parseSportFromLocation,
  patchData,
  postPlaybook,
} from './utils';
import { SplashScreen } from './components/SplashScreen/SplashScreen';
import { PlayAnimator } from './components/PlayAnimator/PlayAnimator';
import { PlaybackControl } from './components/PlaybackControl/PlaybackControl';
import { Play, PlayBook, Position, Snapshot } from './PlayBook';
import { TopNavigation } from './components/Navigation/TopNavigation';
import { BottomNavigation } from './components/Navigation/BottomNavigation';
import { CurrentPlay } from './components/CurrentPlay/CurrentPlay';
import { PlaySelector } from './components/PlaySelector/PlaySelector';
import { ModalContent } from './components/ModalContent/ModalContent';
import { PlayEditor } from './components/PlayEditor/PlayEditor';
import { getSurfaceDimensions } from './components/PlayAnimator/animation-utilities';
import { KeyError } from './components/StaticContent/KeyError';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { AdBlock } from './components/AdBlock/AdBlock';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAXwXBSThetBj4K8dGdKdg4zYkwV5ueFiY',
  authDomain: 'sharetheplay-app.firebaseapp.com',
  projectId: 'sharetheplay-app',
  storageBucket: 'sharetheplay-app.appspot.com',
  messagingSenderId: '367285656429',
  appId: '1:367285656429:web:5fc9037576df11d6171864',
  measurementId: 'G-8FH08GHBGK',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App: FunctionComponent = () => {
  const [playbook, setPlaybook] = useState<PlayBook | null>(null);
  const [playIndex, setPlayIndex] = useState<number>(0);
  const [snapshotCounter, setSnapshotCounter] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackSpeed, setPlaybackSpeed] = useState<
    'Slow' | 'Medium' | 'Fast'
  >('Medium');
  const [showArrows, setShowArrows] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [dirtySnapshot, setDirtySnapshot] = useState<Snapshot | null>(null);
  const [dirty, setDirty] = useState<boolean>(false);
  const [onServer, setOnServer] = useState<boolean>(true);
  const [currentModal, setCurrentModal] = useState<string>('');
  const lastRequestTime = useRef<number | null>(null);

  useEffect(() => {
    if (
      lastRequestTime.current &&
      Date.now() - lastRequestTime.current < 2000
    ) {
      return;
    }
    lastRequestTime.current = Date.now();

    const sport = parseSportFromLocation();
    const pairs = parseKeysFromLocation();

    if (Object.keys(pairs).length > 0) {
      fetchDataFromKeys(pairs)
        .then((response: Response) => response.text())
        .then((body) => {
          logEvent(analytics, 'load');
          setPlaybook(patchData(JSON.parse(body)));
          setLoading(false);
        })
        .catch((_error) => {
          setCurrentModal('Key Error');
        });

      return; // loading
    }

    if (sport) {
      logEvent(analytics, 'empty');
      setPlaybook(createPlaybook(sport));
      setOnServer(false);
      setLoading(false);
      return; // new playbook
    }

    setLoading(false);
    logEvent(analytics, 'splash');
    // splash
  }, []);

  useInterval(() => {
    if (!playbook || !isPlaying) {
      return;
    }

    let counterStep = 0.1;
    if (playbackSpeed === 'Slow') {
      counterStep = 0.05;
    } else if (playbackSpeed === 'Fast') {
      counterStep = 0.2;
    }

    const maxSnapshotIndex = playbook.plays[playIndex].snapshots.length - 1;
    const newSnapshotCounter = snapshotCounter + counterStep;
    if (newSnapshotCounter >= maxSnapshotIndex) {
      setSnapshotCounter(maxSnapshotIndex);
      setIsPlaying(false);
    }
    setSnapshotCounter(newSnapshotCounter);
  }, 100);

  if (loading) {
    if (currentModal === 'Key Error') {
      return <KeyError />;
    }

    return <div>Loading</div>;
  }

  if (!playbook) {
    return <SplashScreen />;
  }

  const play = playbook.plays[playIndex];
  const isOwner = !!playbook.ownersKey;
  const isCoach = !!playbook.coachesKey;

  const onPlayChange = (changedPlay: Play) => {
    logEvent(analytics, 'edit-play');
    const changedPlaybook = deepCopyPlayBook(playbook);
    changedPlaybook.plays[playIndex] = changedPlay;
    setPlaybook(changedPlaybook);
    setDirty(true);
  };

  const onCreatePlay = () => {
    logEvent(analytics, 'create-play');
    const newPlay = createPlay(sport);
    const changedPlaybook = deepCopyPlayBook(playbook);
    changedPlaybook.plays.push(newPlay);
    setPlaybook(changedPlaybook);

    const newPlayIndex = changedPlaybook.plays.length - 1;
    setPlayIndex(newPlayIndex);

    setDirty(true);
  };

  const onCopyPlay = () => {
    logEvent(analytics, 'copy-play');
    const newPlay = deepCopyPlay(play);
    newPlay.name = 'Copy of ' + newPlay.name;

    const changedPlaybook = deepCopyPlayBook(playbook);
    changedPlaybook.plays.push(newPlay);
    setPlaybook(changedPlaybook);

    const newPlayIndex = changedPlaybook.plays.length - 1;
    setPlayIndex(newPlayIndex);

    setDirty(true);
  };

  const onSetPlayIndex = (newPlayIndex: number) => {
    logEvent(analytics, 'set-play-index');
    setPlayIndex(newPlayIndex);
    setSnapshotCounter(0);
  };

  const onDeletePlay = () => {
    logEvent(analytics, 'delete-play');
    const changedPlaybook = deepCopyPlayBook(playbook);
    changedPlaybook.plays.splice(playIndex, 1);
    setPlaybook(changedPlaybook);

    const newPlayIndex = Math.max(0, playIndex - 1);
    setPlayIndex(newPlayIndex);
    setDirty(true);
  };

  const applyChangesToSnapshot = (
    snapshot: Snapshot,
    currentSnapshot: Snapshot,
    target: Snapshot
  ) => {
    Object.keys(snapshot.elements).forEach((key) => {
      // @ts-ignore
      const currentPosition: Position = currentSnapshot.elements[key];
      // @ts-ignore
      const newPosition: Position = snapshot.elements[key];

      if (
        currentPosition.xposition !== newPosition.xposition ||
        currentPosition.yposition !== newPosition.yposition
      ) {
        target.elements[key] = newPosition;
      }
    });

    if (currentSnapshot.description !== snapshot.description) {
      target.description = snapshot.description;
    }
  };

  const onModifySnapshot = (snapshot: Snapshot) => {
    logEvent(analytics, 'modify-snapshot');
    const snapshotIndex = Math.floor(snapshotCounter);
    const changedPlaybook = deepCopyPlayBook(playbook);
    changedPlaybook.plays[playIndex].snapshots[snapshotIndex] = snapshot;
    setPlaybook(changedPlaybook);
    setDirty(true);
    setDirtySnapshot(null);
  };

  const onModifyForwardFromSnapshot = (snapshot: Snapshot) => {
    logEvent(analytics, 'modify-forward-snapshot');
    const snapshotIndex = Math.floor(snapshotCounter);
    const currentSnapshots = playbook.plays[playIndex].snapshots;
    const currentSnapshot = currentSnapshots[snapshotIndex];

    const changedPlaybook = deepCopyPlayBook(playbook);
    const changedSnapshots = changedPlaybook.plays[playIndex].snapshots;

    for (let ctr = snapshotIndex; ctr < currentSnapshots.length; ctr++) {
      applyChangesToSnapshot(snapshot, currentSnapshot, changedSnapshots[ctr]);
    }

    setPlaybook(changedPlaybook);
    setDirty(true);
    setDirtySnapshot(null);
  };

  const onModifyAllFromSnapshot = (snapshot: Snapshot) => {
    logEvent(analytics, 'modify-all-snapshot');
    const snapshotIndex = Math.floor(snapshotCounter);
    const currentSnapshots = playbook.plays[playIndex].snapshots;
    const currentSnapshot = currentSnapshots[snapshotIndex];

    const changedPlaybook = deepCopyPlayBook(playbook);
    const changedSnapshots = changedPlaybook.plays[playIndex].snapshots;

    for (let ctr = 0; ctr < currentSnapshots.length; ctr++) {
      applyChangesToSnapshot(snapshot, currentSnapshot, changedSnapshots[ctr]);
    }

    setPlaybook(changedPlaybook);
    setDirty(true);
    setDirtySnapshot(null);
  };

  // insert after
  const onCaptureSnapshot = (snapshot: Snapshot) => {
    logEvent(analytics, 'capture-snapshot');
    const snapshotIndex = Math.floor(snapshotCounter);
    const changedPlaybook = deepCopyPlayBook(playbook);
    const changedSnapshots = changedPlaybook.plays[playIndex].snapshots;

    const atEnd = snapshotIndex === changedSnapshots.length - 1;
    if (atEnd) {
      changedSnapshots.push(snapshot);
    } else {
      changedSnapshots.splice(snapshotIndex + 1, 0, snapshot);
    }
    setPlaybook(changedPlaybook);
    setDirty(true);
    setDirtySnapshot(null);
    setSnapshotCounter(snapshotIndex + 1);
  };

  const onDeleteSnapshot = () => {
    logEvent(analytics, 'delete-snapshot');
    const snapshotIndex = Math.floor(snapshotCounter);
    const changedPlaybook = deepCopyPlayBook(playbook);
    const changedSnapshots = changedPlaybook.plays[playIndex].snapshots;

    if (changedSnapshots.length > 1) {
      changedSnapshots.splice(snapshotIndex, 1);
      setPlaybook(changedPlaybook);
      setDirty(true);
      setDirtySnapshot(null);
      const newIndex = snapshotIndex > 0 ? snapshotIndex - 1 : 0;
      setSnapshotCounter(newIndex);
    }
  };

  const onSetName = (newName: string) => {
    if (playbook.name !== newName) {
      const newPlayBook = deepCopyPlayBook(playbook);
      newPlayBook.name = newName;
      setPlaybook(newPlayBook);
      setDirty(true);
    }
  };

  const onSetEmail = (newEmail: string) => {
    if (playbook.contactEmail !== newEmail) {
      const newPlayBook = deepCopyPlayBook(playbook);
      newPlayBook.contactEmail = newEmail;
      setPlaybook(newPlayBook);
      setDirty(true);
    }
  };

  const setOffensePlayerLabel = (index: number, newLabel: string) => {
    const { offensePlayerLabels } = playbook;
    if (offensePlayerLabels[index] !== newLabel) {
      const newPlayBook = deepCopyPlayBook(playbook);
      newPlayBook.offensePlayerLabels[index] = newLabel;
      setPlaybook(newPlayBook);
      setDirty(true);
    }
  };

  const setDefensePlayerLabel = (index: number, newLabel: string) => {
    const { defensePlayerLabels } = playbook;
    if (defensePlayerLabels[index] !== newLabel) {
      const newPlayBook = deepCopyPlayBook(playbook);
      newPlayBook.defensePlayerLabels[index] = newLabel;
      setPlaybook(newPlayBook);
      setDirty(true);
    }
  };

  const onSave = () => {
    logEvent(analytics, 'save');
    setSaving(true);
    postPlaybook(playbook)
      .then((response: Response) => {
        if (response.ok) {
          setDirty(false);
          if (!window.location.href.includes('Key')) {
            // new playbook - need to set ownersKey in location
            window.history.replaceState(
              {},
              '',
              '?ownersKey=' + playbook.ownersKey
            );
          }
        } else {
          // todo handle error
          response.text().then((text) => {
            console.log('error', text);
          });
        }
        setSaving(false);
        if (!onServer) {
          setCurrentModal('Save Complete');
        }
        setOnServer(true);
      })
      .catch((error) => {
        console.log('error:', error);
        setSaving(false);
      });
  };

  const { sport } = playbook;
  const isEditor = isOwner || isCoach;

  const cappedSnapshotCounter = Math.min(
    play.snapshots.length - 1,
    snapshotCounter
  );

  const surfaceWidth = getSurfaceDimensions(sport)[0];

  return (
    <div className="App">
      {currentModal !== '' && (
        <ModalContent
          title={currentModal}
          onClose={() => setCurrentModal('')}
          playbook={playbook}
          setOffensePlayerLabel={setOffensePlayerLabel}
          setDefensePlayerLabel={setDefensePlayerLabel}
        />
      )}
      <TopNavigation
        sport={sport}
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
      />
      <AdBlock slot="8819530835" />
      <div className="main-content">
        <PlayAnimator
          playbook={playbook}
          play={play}
          snapshotCounter={cappedSnapshotCounter}
          isEditor={isEditor}
          dirtySnapshot={dirtySnapshot}
          isPlaying={isPlaying}
          setDirtySnapshot={setDirtySnapshot}
          showArrows={showArrows}
        />
        <div className="right-side" style={{ left: `${surfaceWidth}px` }}>
          {isEditor && (
            <PlayEditor
              play={play}
              isPlaying={isPlaying}
              snapshotCounter={cappedSnapshotCounter}
              setSnapshotCounter={setSnapshotCounter}
              dirtySnapshot={dirtySnapshot}
              setDirtySnapshot={setDirtySnapshot}
              onModifySnapshot={onModifySnapshot}
              onModifyForwardFromSnapshot={onModifyForwardFromSnapshot}
              onModifyAllFromSnapshot={onModifyAllFromSnapshot}
              onCaptureSnapshot={onCaptureSnapshot}
              onDeleteSnapshot={onDeleteSnapshot}
            />
          )}
          <PlaybackControl
            play={play}
            isPlaying={isPlaying}
            hasDirtySnapshot={dirtySnapshot !== null}
            setIsPlaying={setIsPlaying}
            snapshotCounter={cappedSnapshotCounter}
            setSnapshotCounter={setSnapshotCounter}
            playbackSpeed={playbackSpeed}
            setPlaybackSpeed={setPlaybackSpeed}
            showArrows={showArrows}
            setShowArrows={setShowArrows}
          />
          <CurrentPlay
            play={play}
            isOwner={isOwner}
            isCoach={isCoach}
            onPlayChange={onPlayChange}
          />
          <PlaySelector
            playbook={playbook}
            playIndex={playIndex}
            setPlayIndex={onSetPlayIndex}
            isPlaying={isPlaying}
            onCreatePlay={onCreatePlay}
            onCopyPlay={onCopyPlay}
            onDeletePlay={onDeletePlay}
            onSetEmail={onSetEmail}
            onSetName={onSetName}
            dirty={dirty}
            onServer={onServer}
            isSnapshotDirty={dirtySnapshot !== null}
            saving={saving}
            onSave={onSave}
            currentModal={currentModal}
            setCurrentModal={setCurrentModal}
          />
        </div>
      </div>
      <AdBlock slot="8819530835" />
      <BottomNavigation
        currentModal={currentModal}
        setCurrentModal={setCurrentModal}
      />
    </div>
  );
};

export default App;
