import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const CreateAPlay: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">Create a Play</p>
      <ul>
        <li>
          Drag each player to their starting positions. When you have them
          standing in the right place, 'Modify Step' to capture the first step
          of the play.
        </li>
        <li>
          Move each player to their next position. When they are all arranged,
          'Capture Step' to create the next step.
        </li>
        <li>
          Press 'Play' button to see your players run through their positions.
        </li>
      </ul>
    </div>
  );
};
