import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const SiteNews: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Site News</div>
      <p>Share the Play has just gone through a tech refresh.</p>
      <p>
        The primary motivation was to reduce costs and get the site to stop
        losing money by switching to more cost effective technologies. Hopefully
        it will also be a little easier for you to use. The playback and play
        editing controls moved around a little and now there is a second 'Play'
        button built into the top right of the playing surface.
      </p>
      <p>
        Please email contact@sharetheplay.com with any reactions, good or bad.
      </p>
      <p>
        In the next few months we are planning to develop a few new features,
        such as:
        <ul>
          <li>
            An actual login that allows a coach to manage multiple playbooks
            without remembering individual keys!
          </li>
          <li>A paid version that removes ads for coaches and players</li>
          <li>
            A layer of "chalk" under the players that shows the full route or
            path the player takes
          </li>
          <li>
            Your idea here. Seriously, send an email and see your great idea on
            the site!
          </li>
        </ul>
      </p>
      <p>
        Again, please email contact@sharetheplay.com and tell us what features
        you would like to see
      </p>
    </div>
  );
};
