import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Privacy: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Privacy</div>
      <p>
        We collect as little information as possible and do not share your
        information with anyone.
      </p>
    </div>
  );
};
