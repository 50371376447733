import { ElementIndex, Play, PlayBook, Position, Snapshot } from './PlayBook';
import { v4 as uuidv4 } from 'uuid';

export type KeyValue = { [key: string]: string };

const bballPlayerLabels = [
  '1',
  '2',
  '3',
  '4',
  '5',
  'PG',
  'SG',
  'SF',
  'PF',
  'C',
  '',
  '',
  '',
];

const soccerPlayerLabels = [
  'GK',
  'LB',
  'CB',
  'CB',
  'RB',
  'LM',
  'CM',
  'CM',
  'CDM',
  'CAM',
  'RM',
  'LW',
  'RW',
  'F',
  'F',
  'S',
  '',
  '',
  '',
];

const usFootballOffensePlayerLabels = [
  'QB',
  'HB',
  'HB',
  'FB',
  'FB',
  'TB',
  'TB',
  'TE',
  'TE',
  'TE',
  'WR',
  'WR',
  'T',
  'G',
  'C',
  'G',
  'T',
  'WR',
  'WR',
  'K',
  'KR',
  'KR',
  '',
  '',
  '',
];

const usFootballDefensePlayerLabels = [
  'DE',
  'DT',
  'DT',
  'DE',
  'NT',
  'CB',
  'CB',
  'FS',
  'LB',
  'LB',
  'LB',
  'LB',
  'SS',
  'CB',
  'CB',
  '',
  '',
  '',
];

const volleyballPlayerLabels = [
  'OP',
  'MB',
  'MH',
  'OH',
  'OP',
  'MB',
  'MH',
  'OH',
  'S',
  'S',
  'L',
  '',
  '',
  '',
];

const lacrossePlayerLabels = [
  'G',
  'D1',
  'D2',
  'D3',
  'M1',
  'M2',
  'M3',
  'A1',
  'A2',
  'A3',
  '',
  '',
  '',
];

const wLacrossePlayerLabels = [
  'G',
  'D1',
  'D2',
  'D3',
  'D4',
  'M1',
  'M2',
  'M3',
  'A1',
  'A2',
  'A3',
  'A4',
  '',
  '',
  '',
];

const iceHockeyPlayerLabels = [
  'GK',
  'LD',
  'RD',
  'LW',
  'C',
  'RW',
  'LD',
  'RD',
  'LW',
  'C',
  'RW',
  '',
  '',
];

const fieldHockeyPlayerLabels = [
  'GK',
  'D1',
  'D2',
  'D3',
  'D4',
  'M1',
  'M2',
  'M3',
  'M4',
  'M5',
  'F1',
  'F2',
  'F3',
  '',
  '',
  '',
];

const sportToOffenseLabelsMap = {
  bball: bballPlayerLabels,
  soccer: soccerPlayerLabels,
  football: soccerPlayerLabels,
  usfootball: usFootballOffensePlayerLabels,
  volleyball: volleyballPlayerLabels,
  lacrosse: lacrossePlayerLabels,
  wlacrosse: wLacrossePlayerLabels,
  icehockey: iceHockeyPlayerLabels,
  fieldhockey: fieldHockeyPlayerLabels,
};

const sportToDefenseLabelsMap = {
  bball: bballPlayerLabels,
  soccer: soccerPlayerLabels,
  football: soccerPlayerLabels,
  usfootball: usFootballDefensePlayerLabels,
  volleyball: volleyballPlayerLabels,
  lacrosse: lacrossePlayerLabels,
  wlacrosse: wLacrossePlayerLabels,
  icehockey: iceHockeyPlayerLabels,
  fieldhockey: fieldHockeyPlayerLabels,
};

const fireApiUrl = 'https://sharetheplay-app.uc.r.appspot.com';
const localFireURL = 'http://localhost:8080';
const keyTypes = ['ownersKey', 'coachesKey', 'playersKey'];

// order matters
const sports = [
  'wlacrosse',
  'lacrosse',
  'bball',
  'usfootball',
  'football',
  'soccer',
  'volleyball',
  'icehockey',
  'fieldhockey',
];

function createGuid(): string {
  return uuidv4();
}

function keyCount(elements: ElementIndex, prefix: string) {
  return Object.keys(elements).filter((key) => key.startsWith(prefix)).length;
}

function onEachElementIndex(
  playbook: PlayBook,
  action: (elements: ElementIndex) => void
) {
  for (let playCtr = 0; playCtr < playbook.plays.length; playCtr++) {
    const play = playbook.plays[playCtr];
    for (
      let snapshotCtr = 0;
      snapshotCtr < play.snapshots.length;
      snapshotCtr++
    ) {
      const elements = play.snapshots[snapshotCtr].elements;
      action(elements);
    }
  }
}

export function patchData(originalPlaybook: PlayBook) {
  let playbook = deepCopyPlayBook(originalPlaybook);

  // find first non-empty play
  const firstPlay = playbook.plays.find((play) => play.snapshots.length > 0);
  if (!firstPlay) {
    return playbook;
  }
  const { elements } = firstPlay.snapshots[0];
  const squareCount = keyCount(elements, 'square');
  const playersPerTeam = Math.floor(squareCount / 2);

  const descriptionCount = keyCount(elements, 'description');
  const ballCount =
    keyCount(elements, 'ball') + keyCount(elements, 'spareBall');
  const coneCount = keyCount(elements, 'cone');

  if (!playbook.offensePlayerLabels) {
    playbook.offensePlayerLabels = [];
    playbook.defensePlayerLabels = [];

    for (let ctr = 1; ctr <= playersPerTeam; ctr++) {
      playbook.offensePlayerLabels.push('' + ctr);
      playbook.defensePlayerLabels.push('' + ctr);
    }
  }

  if (squareCount > playersPerTeam * 2) {
    const badName = 'square' + squareCount;
    onEachElementIndex(playbook, (elements) => {
      elements[badName] && delete elements[badName];
    });
  }

  if (descriptionCount === 0) {
    const descriptionPosition = { xposition: 44, yposition: 444 };
    onEachElementIndex(
      playbook,
      (elements) => (elements.description1 = descriptionPosition)
    );
  }

  if (ballCount < 6) {
    const ballPosition = { xposition: -2, yposition: 2 };
    onEachElementIndex(playbook, (elements) => {
      for (let ballCtr = ballCount + 1; ballCtr <= 6; ballCtr++) {
        elements['ball' + ballCtr] = ballPosition;
      }
    });
  }

  if (coneCount < 15) {
    const conePosition = { xposition: -2, yposition: 22 };
    onEachElementIndex(playbook, (elements) => {
      for (let coneCtr = coneCount + 1; coneCtr <= 15; coneCtr++) {
        elements['cone' + coneCtr] = conePosition;
      }
    });
  }

  if (!playbook.playbookID) {
    playbook.playbookID = createGuid();
  }

  for (let ctr = 0; ctr < playbook.plays.length; ctr++) {
    const play = playbook.plays[ctr];
    if (play.snapshots.length === 0) {
      const newPlay = createPlay(playbook.sport);
      newPlay.name = play.name;
      newPlay.description = play.description;
      playbook.plays[ctr] = newPlay;
    }
  }
  return playbook;
}

export function parseSportFromLocation() {
  for (let ctr = 0; ctr < sports.length; ctr++) {
    if (window.location.href.includes(sports[ctr])) {
      return sports[ctr];
    }
  }

  return null;
}

export function parseKeysFromLocation(): KeyValue {
  const rawSearch = window.location.search;
  const map: KeyValue = {};
  if (!rawSearch || rawSearch.length < 4) {
    return map;
  }

  rawSearch
    .slice(1)
    .split('&')
    .forEach((token) => {
      const [key, value] = token.split('=');
      if (keyTypes.includes(key)) {
        map[key] = value;
      }
    });

  return map;
}

function buildFetchURL() {
  return window.location.href.includes('localServer')
    ? localFireURL
    : fireApiUrl;
}

function buildPostURL() {
  return window.location.href.includes('localServer')
    ? localFireURL
    : fireApiUrl;
}

export function createShareLink(
  playbook: PlayBook,
  text: string,
  keyType: string
) {
  const link = formatPlaybookLink(keyType, playbook);
  return (
    <div className="link-description">
      <div>{text}</div>
      <a href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    </div>
  );
}

export function fetchDataFromKeys(pairs: KeyValue): Promise<Response> {
  const url = buildFetchURL();
  const queryString = Object.keys(pairs)
    .map((key) => key + '=' + pairs[key])
    .join('&');
  return fetch(url + '?' + queryString);
}

export function postPlaybook(playbook: PlayBook): Promise<Response> {
  const url = buildPostURL();
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(playbook),
  };

  return fetch(url + '/', requestOptions);
}

function addElements(
  elementIndex: ElementIndex,
  name: string,
  startIndex: number,
  stopIndex: number,
  xposition: number,
  yposition: number,
  yStep: number
) {
  for (let index = startIndex; index <= stopIndex; index++) {
    const position: Position = {
      xposition,
      yposition: yposition + index * yStep,
    };
    const key = name + index;
    elementIndex[key] = position;
  }
}

export function createPlay(sport: string) {
  // @ts-ignore
  const offenseLabelsLength = sportToOffenseLabelsMap[sport].length;
  // @ts-ignore
  const defenceLabelsLength = sportToDefenseLabelsMap[sport].length;
  const labelsLength = offenseLabelsLength + defenceLabelsLength;
  const elements: ElementIndex = {};
  addElements(elements, 'ball', 1, 7, -2, 0, 0);
  addElements(elements, 'square', 1, labelsLength, -2, 0, 20);
  addElements(elements, 'cone', 1, 15, -2, (labelsLength + 1) * 20, 0);
  addElements(elements, 'description', 1, 1, 100, 400, 0);

  const snapshot: Snapshot = {
    description: 'Step description - drag me around',
    elements,
  };
  const play = {
    currentSnapshotIndex: 0,
    description: 'Describe your play',
    name: 'Name your play',
    snapshots: [snapshot],
  };

  return play;
}

export function createPlaybook(sport: string) {
  // @ts-ignore
  const offensePlayerLabels = sportToOffenseLabelsMap[sport];
  // @ts-ignore
  const defensePlayrLabels = sportToDefenseLabelsMap[sport];
  const play = createPlay(sport);
  return {
    sport,
    name: 'Playbook name',
    contactEmail: 'youremail@here.com',
    plays: [play],
    offensePlayerLabels: [...offensePlayerLabels],
    defensePlayerLabels: [...defensePlayrLabels],
    ownersKey: createGuid(),
    coachesKey: createGuid(),
    playersKey: createGuid(),
    playbookID: createGuid(),
  };
}

export function createButton(
  text: string,
  action: () => void,
  isDisabledPredicate: () => boolean,
  enabledTooltip?: string,
  disabledTooltip?: string
) {
  const disabled = isDisabledPredicate();
  const tooltip = disabled ? disabledTooltip : enabledTooltip;

  return (
    <button onClick={action} disabled={disabled} title={tooltip}>
      {text}
    </button>
  );
}

export function formatPlaybookLink(keyType: string, playbook: PlayBook) {
  // @ts-ignore
  const keyValue = playbook[keyType];
  return `https://${playbook.sport}.sharetheplay.com?${keyType}=${keyValue}`;
}

export function deepCopySnapshot(original: Snapshot): Snapshot {
  return JSON.parse(JSON.stringify(original));
}

export function deepCopyPlayBook(original: PlayBook): PlayBook {
  return JSON.parse(JSON.stringify(original));
}

export function deepCopyPlay(original: Play): Play {
  return JSON.parse(JSON.stringify(original));
}
