import { FunctionComponent } from 'react';
import './ModalContent.scss';
import { About } from '../StaticContent/About';
import { Contact } from '../StaticContent/Contact';
import { Privacy } from '../StaticContent/Privacy';
import { Features } from '../StaticContent/Features';
import { SiteNews } from '../StaticContent/SiteNews';
import { Share } from '../StaticContent/Share';
import { PlayBook } from '../../PlayBook';
import { LabelEditor } from '../LabelEditor/LabelEditor';
import { SaveComplete } from '../StaticContent/SaveComplete';
import { KeyError } from '../StaticContent/KeyError';

type ModalContentProps = {
  title: string;
  onClose: () => void;
  playbook: PlayBook;
  setOffensePlayerLabel: (index: number, label: string) => void;
  setDefensePlayerLabel: (index: number, label: string) => void;
};
export const ModalContent: FunctionComponent<ModalContentProps> = ({
  title,
  onClose,
  playbook,
  setOffensePlayerLabel,
  setDefensePlayerLabel,
}: ModalContentProps) => {
  return (
    <div className="ModalContent">
      <div className="modal-content-top">
        <div className="modal-content-closer" onClick={onClose}>
          X
        </div>
      </div>
      <div className="modal-content-body">
        {title === 'About' && <About />}
        {title === 'Contact' && <Contact />}
        {title === 'Privacy' && <Privacy />}
        {title === 'Features' && <Features />}
        {title === 'Site News' && <SiteNews />}
        {title === 'Share' && <Share playbook={playbook} />}
        {title === 'Key Error' && <KeyError />}
        {title === 'Save Complete' && <SaveComplete playbook={playbook} />}
        {title === 'Edit Labels' && (
          <LabelEditor
            offensePlayerLabels={playbook.offensePlayerLabels}
            defensePlayerLabels={playbook.defensePlayerLabels}
            setOffensePlayerLabel={setOffensePlayerLabel}
            setDefensePlayerLabel={setDefensePlayerLabel}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};
