import { FunctionComponent } from 'react';

import { Play, Snapshot } from '../../PlayBook';
import './PlayEditor.scss';
import { createButton, deepCopySnapshot } from '../../utils';

type PlayEditorProps = {
  play: Play;
  isPlaying: boolean;
  snapshotCounter: number;
  setSnapshotCounter: (counter: number) => void;
  dirtySnapshot: Snapshot | null;
  setDirtySnapshot: (snapshot: Snapshot | null) => void;
  onModifySnapshot: (snapshot: Snapshot) => void;
  onModifyForwardFromSnapshot: (snapshot: Snapshot) => void;
  onModifyAllFromSnapshot: (snapshot: Snapshot) => void;
  onCaptureSnapshot: (snapshot: Snapshot) => void;
  onDeleteSnapshot: () => void;
};

export const PlayEditor: FunctionComponent<PlayEditorProps> = ({
  play,
  isPlaying,
  snapshotCounter,
  setSnapshotCounter,
  dirtySnapshot,
  setDirtySnapshot,
  onModifySnapshot,
  onModifyForwardFromSnapshot,
  onModifyAllFromSnapshot,
  onCaptureSnapshot,
  onDeleteSnapshot,
}: PlayEditorProps) => {
  const editButtonsDisabled = isPlaying || dirtySnapshot === null;
  const snapshotIndex = Math.floor(snapshotCounter);
  const atEnd = snapshotIndex === play.snapshots.length - 1;
  const snapshotUnderEdit = dirtySnapshot
    ? dirtySnapshot
    : play.snapshots[snapshotIndex];

  const onCaptureStep = () => {
    if (!dirtySnapshot) {
      return;
    }

    onCaptureSnapshot(dirtySnapshot);
  };

  const onModifyStep = () => {
    if (!dirtySnapshot) {
      return;
    }

    onModifySnapshot(dirtySnapshot);
  };

  const onModifyForwardStep = () => {
    if (!dirtySnapshot) {
      return;
    }

    onModifyForwardFromSnapshot(dirtySnapshot);
  };

  const onModifyAllStep = () => {
    if (!dirtySnapshot) {
      return;
    }

    onModifyAllFromSnapshot(dirtySnapshot);
  };

  const onRevertStep = () => {
    setDirtySnapshot(null);
  };

  const captureStepButton = createButton(
    'Capture Step',
    onCaptureStep,
    () => editButtonsDisabled,
    `Capture the current changes in a new step ${snapshotIndex + 2}`,
    'First, move some  players'
  );

  const modifyStepButton = createButton(
    'Modify Step',
    onModifyStep,
    () => editButtonsDisabled,
    'Modify the current step with the changes',
    'First, move some  players'
  );

  const modifyForwardButton = createButton(
    'Modify Forward',
    onModifyForwardStep,
    () => editButtonsDisabled || atEnd,
    'Modify the current step and all subsequent steps',
    'First, move some  players'
  );

  const modifyAllButton = createButton(
    'Modify All',
    onModifyAllStep,
    () => editButtonsDisabled,
    'Modify all steps with the changes',
    'First, move some  players'
  );

  const revertButton = createButton(
    'Revert Step Changes',
    onRevertStep,
    () => editButtonsDisabled || !dirtySnapshot,
    'Undo all changes to the current step'
  );

  const deleteButton = createButton(
    'Delete Step',
    onDeleteSnapshot,
    () => isPlaying || play.snapshots.length < 2
  );

  const onDescriptionChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newDirtySnapshot: Snapshot = deepCopySnapshot(snapshotUnderEdit);
    newDirtySnapshot.description = event.currentTarget.value;
    setDirtySnapshot(newDirtySnapshot);
  };

  return (
    <div className="PlayEditor content">
      <div>
        <input
          onChange={onDescriptionChange}
          value={snapshotUnderEdit.description}
        />
      </div>
      <div>{captureStepButton}</div>
      <div>
        {modifyStepButton}
        {modifyForwardButton}
        {modifyAllButton}
      </div>
      <div>
        {revertButton}
        {deleteButton}
      </div>
    </div>
  );
};
