import { FunctionComponent } from 'react';
import './AdBlock.scss';

import { Adsense } from '@ctrl/react-adsense';

type AdBlockProps = {
  slot: string;
};

export const ADS_HEIGHT = 90;

export const AdBlock: FunctionComponent<AdBlockProps> = ({
  slot,
}: AdBlockProps) => {
  const adStyle = {
    display: 'inline-block',
    width: '728px',
    height: '' + ADS_HEIGHT + 'px',
  };
  return (
    <div className="AdBlock">
      <Adsense
        client="ca-pub-7275625532748995"
        slot={slot}
        style={adStyle}
        format=""
      />
    </div>
  );
};
