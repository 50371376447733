import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Save: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">Save</p>
      <ul>
        <li>
          You must press the 'Save Plays' button before you can share your work.
        </li>
        <li>
          Use the 'Share' button to see the link that your players will use to
          view your playbook.
        </li>
        <li>
          It is very important to keep track of the Owner Link. You can bookmark
          it and / or email it to yourself. It is also important to protect the
          Owner Link. Anyone who has your Owner Link has all of the privileges
          that you do.
        </li>
      </ul>
    </div>
  );
};
