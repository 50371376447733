import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const SeeThePlay: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">See the Play</p>
      <ul>
        <li>
          The 'Show the Play' button lets you see the play as an animation.
        </li>
        <li>
          As your plays get longer, it may help to use the 'Pause' and 'Resume'
          button to control the action.
        </li>
        <li>
          You can also walk through the play one step at a time with the '&lt;'
          and '&gt;' buttons.
        </li>
        <li>
          The 'Modify Step' and 'Delete Step' buttons affect the current step.
        </li>
        <li>
          Capturing a new step inserts a step between the current step and the
          next step.
        </li>
      </ul>
    </div>
  );
};
