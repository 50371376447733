import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const SamplePlays: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">Sample Plays</p>
      <p>
        <a
          href="https://bball.sharetheplay.com/?playersKey=3F29DG12F5B6981B7"
          target="_blank"
          rel="noreferrer"
        >
          Simple Inbounds Stack
        </a>
      </p>
      <p>
        <a
          href="https://bball.sharetheplay.com/?playersKey=89329G12F745D17B2"
          target="_blank"
          rel="noreferrer"
        >
          Inbounder is Cutter
        </a>
      </p>
      <p>
        <a
          href="https://bball.sharetheplay.com/?playersKey=624D7G12FBD200980"
          target="_blank"
          rel="noreferrer"
        >
          Overload Featuring the 1
        </a>
      </p>
      <p>
        <a
          href="https://bball.sharetheplay.com/?playersKey=35762G12E8ED76ED5"
          target="_blank"
          rel="noreferrer"
        >
          Moving without the ball
        </a>
      </p>
    </div>
  );
};
