import { FunctionComponent } from 'react';
import './PlaySelector.scss';
import { Play, PlayBook } from '../../PlayBook';
import { createButton } from '../../utils';

type PlaySelectorProps = {
  playbook: PlayBook;
  playIndex: number;
  setPlayIndex: (index: number) => void;
  onSetEmail: (email: string) => void;
  onSetName: (name: string) => void;
  isPlaying: boolean;
  dirty: boolean;
  onServer: boolean;
  isSnapshotDirty: boolean;
  saving: boolean;
  onSave: () => void;
  onCreatePlay: () => void;
  onCopyPlay: () => void;
  onDeletePlay: () => void;
  currentModal: string;
  setCurrentModal: (modalName: string) => void;
};

export const PlaySelector: FunctionComponent<PlaySelectorProps> = ({
  playbook,
  playIndex,
  setPlayIndex,
  onSetEmail,
  onSetName,
  isPlaying,
  dirty,
  onServer,
  isSnapshotDirty,
  saving,
  onSave,
  onCreatePlay,
  onCopyPlay,
  onDeletePlay,
  currentModal,
  setCurrentModal,
}: PlaySelectorProps) => {
  const playElements = playbook.plays.map((play: Play, index) => {
    const selectedClass = index === playIndex ? ' selected ' : ' ';
    const dirtyClass = isSnapshotDirty ? ' dirty ' : ' ';
    const className = 'play-item' + selectedClass + dirtyClass;
    const tooltipText = isSnapshotDirty
      ? `Finish with ${playbook.plays[playIndex].name} before switching`
      : '';
    return (
      <div
        key={index}
        className={className}
        title={tooltipText}
        onClick={() => !isSnapshotDirty && setPlayIndex(index)}
      >
        {play.name}
      </div>
    );
  });

  const isOwner = !!playbook.ownersKey;
  const isCoach = !!playbook.coachesKey;
  const isEditor = isOwner || isCoach;

  const createPlayButton = createButton(
    'Create',
    onCreatePlay,
    () => isPlaying || (!isOwner && !isCoach),
    'Create a new play'
  );

  const copyPlayButton = createButton(
    'Copy',
    onCopyPlay,
    () => isPlaying || (!isOwner && !isCoach),
    `Make a copy of ${playbook.plays[playIndex].name}`
  );

  const deletePlayButton = createButton(
    'Delete',
    onDeletePlay,
    () => isPlaying || (!isOwner && !isCoach) || playbook.plays.length < 2
  );

  const onNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newName = event.currentTarget.value;
    onSetName(newName);
  };
  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newEmail = event.currentTarget.value;
    onSetEmail(newEmail);
  };

  const saveButton = createButton(
    'Save',
    onSave,
    () => !dirty,
    'Save all changes to all plays',
    'Make some changes, then save'
  );

  const reloadButton = createButton(
    'Reload',
    () => window.location.reload(),
    () => !dirty,
    'Reload to undo all changes to all plays since the last save',
    ''
  );

  const shareButton = createButton(
    'Share',
    () => {
      if (currentModal === 'Share') {
        setCurrentModal('');
      } else {
        setCurrentModal('Share');
      }
    },
    () => dirty || isSnapshotDirty || !onServer,
    '',
    'Save before you share'
  );

  const editLabelsButton = createButton(
    'Edit Labels',
    () => {
      if (currentModal === 'Edit Labels') {
        setCurrentModal('');
      } else {
        setCurrentModal('Edit Labels');
      }
    },
    () => false
  );

  return (
    <div className="PlaySelector content">
      <div className="label">Playbook</div>
      {isOwner ? (
        <input onChange={onNameChange} value={playbook.name} />
      ) : (
        <div>{playbook.name}</div>
      )}
      <div className="label">by</div>
      {isOwner ? (
        <input onChange={onEmailChange} value={playbook.contactEmail} />
      ) : (
        <div>{playbook.contactEmail}</div>
      )}
      <div className="playbook-buttons">
        {isEditor && saveButton}
        {isEditor && reloadButton}
        {isOwner && shareButton}
        {isEditor && editLabelsButton}
        {saving && <span>Saving</span>}
      </div>
      <div className="label">Plays</div>
      <div className="play-list">{playElements}</div>
      <div className="play-list-buttons">
        {createPlayButton}
        {copyPlayButton}
        {deletePlayButton}
      </div>
    </div>
  );
};
