import ConeImage from './cone.png';
import OffenseImage from './offense.gif';
import DefenseImage from './defense.gif';
import { PlayBook } from '../../PlayBook';

import BBallBallImage from './ball-bball.gif';
import USFootbsllBallImage from './ball-usfootball.png';
import SoccerBallImage from './ball-soccer.png';
import VolleyballBallImage from './ball-volleyball.png';
import LacrosseBallImage from './ball-lacrosse.png';
import IceHockeyPuckImage from './puck-icehockey.png';

import BBallSurfaceImage from './surface-bball.png';
import USFootballSurfaceImage from './surface-usfootball.png';
import SoccerSurfaceImage from './surface-soccer.png';
import VolleyballSurfaceImage from './surface-volleyball.png';
import LacrosseSurfaceImage from './surface-lacrosse.png';
import WLacrosseSurfaceImage from './surface-wlacrosse.png';
import IceHockeySurfaceImage from './surface-icehockey.png';
import FieldHockySurfaceImage from './surface-fieldhockey.png';

const sportToSurfaceImageMap = {
  bball: BBallSurfaceImage,
  usfootball: USFootballSurfaceImage,
  soccer: SoccerSurfaceImage,
  football: SoccerSurfaceImage,
  volleyball: VolleyballSurfaceImage,
  lacrosse: LacrosseSurfaceImage,
  wlacrosse: WLacrosseSurfaceImage,
  icehockey: IceHockeySurfaceImage,
  fieldhockey: FieldHockySurfaceImage,
};

const sportToBallImageMap = {
  bball: BBallBallImage,
  usfootball: USFootbsllBallImage,
  soccer: SoccerBallImage,
  football: SoccerBallImage,
  volleyball: VolleyballBallImage,
  lacrosse: LacrosseBallImage,
  wlacrosse: LacrosseBallImage,
  icehockey: IceHockeyPuckImage,
  fieldhockey: LacrosseBallImage,
};

const sportToDimensionsMap = {
  bball: [404, 700],
  usfootball: [500, 975],
  soccer: [543, 856],
  football: [543, 856],
  volleyball: [480, 780],
  lacrosse: [480, 780],
  wlacrosse: [492, 828],
  icehockey: [412, 824],
  fieldhockey: [480, 720],
};
export function getZIndex(key: string) {
  if (key.startsWith('square')) {
    return 100;
  }

  if (key.startsWith('ball')) {
    return 70;
  }

  if (key.startsWith('cone')) {
    return 50;
  }
}

export function getSurfaceImage(sport: string) {
  // @ts-ignore
  return `url(${sportToSurfaceImageMap[sport]})`;
}

export function getSurfaceDimensions(sport: string) {
  // @ts-ignore
  return sportToDimensionsMap[sport];
}

function getBallImage(sport: string) {
  // @ts-ignore
  return sportToBallImageMap[sport];
}

export function getImage(key: string, sport: string, playbook: PlayBook) {
  if (key.startsWith('cone')) {
    return ConeImage;
  }

  if (key.startsWith('square')) {
    const maxPlayerIndex = playbook.offensePlayerLabels.length - 1;
    const index = parseInt(key.slice(6)) - 1;

    if (index <= maxPlayerIndex) {
      return OffenseImage;
    } else {
      return DefenseImage;
    }
  }

  if (key.startsWith('ball') || key.startsWith('spareBall')) {
    return getBallImage(sport);
  }

  return null;
}

export function getPlayerLabel(key: string, playbook: PlayBook) {
  if (!key.startsWith('square')) {
    return '';
  }

  const maxPlayerIndex = playbook.offensePlayerLabels.length - 1;

  let index = parseInt(key.slice(6)) - 1;
  if (index <= maxPlayerIndex) {
    if (
      playbook.offensePlayerLabels &&
      playbook.offensePlayerLabels.length > index
    ) {
      return playbook.offensePlayerLabels[index];
    }

    return '';
  }

  index = index - playbook.offensePlayerLabels.length;
  if (
    playbook.defensePlayerLabels &&
    playbook.defensePlayerLabels.length > index
  ) {
    return playbook.defensePlayerLabels[index];
  }

  return '';
}

export function interpolate(first: number, second: number, ratio: number) {
  return first + (second - first) * ratio;
}
