import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Contact: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Contact</div>
      <p>
        Please let us know what you like, what you dislike and what you need in
        the future. Or just let us know where you are from and how you use
        ShareThePlay.com
      </p>

      <p>The best way to reach us is: contact@ShareThePlay.com</p>
    </div>
  );
};
