import { FunctionComponent } from 'react';
import './StaticContent.scss';

export const Overview: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <div className="static-content-title">Features</div>
      <p>
        ShareThePlay.com allows you to create plays and then share the plays
        with your team. Our goal is to help your players learn your plays at
        their own pace through animated playback. You can then spend your
        valuable practice time doing what you do best - turning kids into
        athletes and athletes into great players!
      </p>
      <p>
        Plays can be viewed in most modern web browsers and on many mobile
        devices, including the iPhone™ and Android™ phones.
      </p>
    </div>
  );
};
