import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { PlayBook } from '../../PlayBook';
import { createShareLink } from '../../utils';

type SaveCompleteProps = {
  playbook: PlayBook;
};
export const SaveComplete: FunctionComponent<SaveCompleteProps> = ({
  playbook,
}: SaveCompleteProps) => {
  return (
    <div className="StaticContent">
      <p className="static-content-title">Save Complete</p>
      <p>Your playbook has been saved!</p>
      <p>
        ShareThePlay.com does not require a registration or login. Instead, as
        the owner of the playbook, you keep a unique and unguessable link to
        your playbook.
      </p>
      {createShareLink(
        playbook,
        'You should keep your owners key somewhere safe. Maybe email it to yourself or copy it to a google doc.',
        'ownersKey'
      )}
      <div className="info-heading">
        Do not lose this link! Do not share it!
      </div>
      <p>
        When you are ready to share your work with coaches or players, use the
        'Share' button next to the 'Save' button to reveal the unique and
        unguessable links that players and coaches will use to visit your
        playbook
      </p>
      <p>
        Warning: In case of a lost owners link, we need a contact email. We
        periodically get requests to help find lost owners links. If you enter a
        contact email then we can easily help you. If you enter an organization
        name, then we will contact the organization and try our best to get the
        link to the right party. If you don't enter any identifying information
        then we will be unable to help you.
      </p>
    </div>
  );
};
