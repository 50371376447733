import { FunctionComponent } from 'react';
import './StaticContent.scss';
import BBallBallImage from '../PlayAnimator/ball-bball.gif';
import AFootballImage from '../PlayAnimator/ball-usfootball.png';
import VolleyballImage from '../PlayAnimator/ball-volleyball.png';
import LacrosseImage from '../PlayAnimator/ball-lacrosse.png';
import SoccerImage from '../PlayAnimator/ball-soccer.png';

export const ListOfSports: FunctionComponent = () => {
  return (
    <div className="StaticContent">
      <p className="info-heading">Get Started</p>
      <p>
        You can create and share a play in minutes, without signing up for
        anything or providing any personal information.
      </p>
      <p>
        <img src={BBallBallImage} alt="ball" /> Basketball{' '}
        <a href="https://bball.sharetheplay.com/">
          https://bball.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={AFootballImage} alt="ball" /> American Football{' '}
        <a href="https://usfootball.sharetheplay.com/">
          https://usfootball.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={SoccerImage} alt="ball" /> Football in the Rest of the World{' '}
        <a href="https://football.sharetheplay.com/">
          https://football.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={SoccerImage} alt="ball" /> Soccer{' '}
        <a href="https://soccer.sharetheplay.com/">
          https://soccer.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={VolleyballImage} alt="ball" /> Volleyball{' '}
        <a href="https://volleyball.sharetheplay.com/">
          https://volleyball.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={LacrosseImage} alt="ball" /> Lacrosse{' '}
        <a href="https://lacrosse.sharetheplay.com/">
          https://lacrosse.sharetheplay.com/
        </a>
      </p>
      <p>
        <img src={LacrosseImage} alt="ball" /> Women's Lacrosse{' '}
        <a href="https://wlacrosse.sharetheplay.com/">
          https://wlacrosse.sharetheplay.com/
        </a>
      </p>
    </div>
  );
};
