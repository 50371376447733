import { FunctionComponent } from 'react';
import './TopNavigation.scss';
import { ContentLink } from './ContentLink';
import { NavigationLink } from './NavigationLink';

type TopNavigationProps = {
  sport: string | null;
  currentModal: string;
  setCurrentModal: (modalName: string) => void;
};

export const TopNavigation: FunctionComponent<TopNavigationProps> = ({
  sport,
  currentModal,
  setCurrentModal,
}: TopNavigationProps) => {
  return (
    <div className="TopNavigation">
      <div className="left-titles">
        <a
          href="https://www.sharetheplay.com"
          className="page-title"
          target="_blank"
          rel="noreferrer"
        >
          ShareThePlay
        </a>
        <div className="page-sub-title">
          Create plays. Share plays. For free.
        </div>
      </div>
      {sport && (
        <div className="right-links">
          <NavigationLink
            href={'https://' + sport + '.sharetheplay.com'}
            text="New Playbook"
          />
          <ContentLink
            text="Features"
            currentModal={currentModal}
            setCurrentModal={setCurrentModal}
          />
          <ContentLink
            text="Site News"
            currentModal={currentModal}
            setCurrentModal={setCurrentModal}
          />
          <NavigationLink
            href="http://blog.sharetheplay.com/p/basketball.html"
            text="Prepared Plays"
          />
        </div>
      )}
    </div>
  );
};
