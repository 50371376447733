import { FunctionComponent } from 'react';
import './StaticContent.scss';
import { Overview } from './Overview';
import { CreateAPlay } from './CreateAPlay';
import { Save } from './Save';
import { Collaborate } from './Collaborate';

export const Features: FunctionComponent = () => {
  return (
    <div>
      <Overview />
      <CreateAPlay />
      <Save />
      <Collaborate />
    </div>
  );
};
